import React, { useState, useEffect } from 'react';

function Reviews() {
    const reviewsPath = '/img/reviews';
    const reviews = [
        `${reviewsPath}/Crystal.png`,
        `${reviewsPath}/Cristina.png`,
        `${reviewsPath}/Kristine.png`,
        `${reviewsPath}/Chelsea.png`,
        `${reviewsPath}/Lacey.png`,
        `${reviewsPath}/Monica.png`,
        `${reviewsPath}/Hendrick.png`,
        `${reviewsPath}/Jay.png`,
        `${reviewsPath}/Kristie.png`,
    ];

    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3);

    const updateGrid = (direction: string) => {
        setCurrentPageIndex((prevIndex) => {
            if (direction === 'left') {
                return (prevIndex - itemsPerPage + reviews.length) % reviews.length;
            } else {
                return (prevIndex + itemsPerPage) % reviews.length;
            }
        });
    };

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setItemsPerPage(1);
        } else if (window.innerWidth < 1024) {
            setItemsPerPage(2);
        } else {
            setItemsPerPage(3);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const reviewClasses = "max-2xl:w-100 max-2xl:h-100 w-200 h-200 flex justify-center items-center rounded-lg shadow-2xl overflow-hidden";

    let indices = [];
    for (let i = 0; i < itemsPerPage; i++) {
        indices.push((currentPageIndex + i) % reviews.length);
    }

    return (
        <div className="bg-white w-full">
            <div className="flex flex-col justify-center items-center pb-10 mx-auto">
                <h2 className="order-first w-full text-center my-10 font-size text-4xl">
                    Elite Yelp Reviews
                </h2>
                <div className="flex items-center mx-auto">
                    <div
                        className="text-3xl cursor-pointer select-none mr-4"
                        onClick={() => updateGrid('left')}
                    >
                        &#9664;
                    </div>
                    <div className={`grid gap-4 ${itemsPerPage === 1 ? 'grid-cols-1' : itemsPerPage === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
                        {indices.map(index => (
                            <div key={index} className={reviewClasses}>
                                <img src={reviews[index]} alt="Review" />
                            </div>
                        ))}
                    </div>
                    <div
                        className="text-3xl cursor-pointer select-none ml-4"
                        onClick={() => updateGrid('right')}
                    >
                        &#9654;
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;
