import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create a context for the source parameter with a default value of "default"
const SourceContext = createContext<string>('default');
const SourceUpdateContext = createContext<React.Dispatch<React.SetStateAction<string>>>(() => {});

export function useSource() {
  return useContext(SourceContext);
}

export function useUpdateSource() {
  return useContext(SourceUpdateContext);
}

export function SourceProvider({ children }: { children: React.ReactNode }) {
  const [source, setSource] = useState<string>('default');
  console.log('Source:', source);
  return (
    <SourceContext.Provider value={source}>
      <SourceUpdateContext.Provider value={setSource}>
        {children}
      </SourceUpdateContext.Provider>
    </SourceContext.Provider>
  );
}

export function SourceWrapper({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const setSource = useUpdateSource();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialSource = searchParams.get('source');
    if (initialSource) {
      setSource(initialSource);
    }
  }, [location, setSource]);

  return <>{children}</>;
}
