import React from 'react'

function Hero() {
    return (
        <div className="h-[25vh] bg-white flex items-center justify-center">
            <h1 className="text-black text-5xl font-playfair mt-[100px]">
                Contact Us
            </h1>
        </div>
    )
}

export default Hero
