import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="fixed top-0 left-0 w-full bg-white shadow-md py-4 z-10">
            <div className="container mx-auto flex justify-between items-center px-4">
                <div className="flex items-center">
                    <Link to="/" className="flex items-center">
                        <img src="Logo.png" alt="Logo" className="h-12 w-12 mr-4" />
                        <h1 className="text-2xl font-bold">Evidence Cleaning</h1>
                    </Link>
                </div>
                <div className="hidden md:flex space-x-4 items-center">
                    <Link to="/" className="px-4 py-2">Home</Link>
                    <Link to="/services" className="px-4 py-2">Services</Link>
                    <Link to="/contact-us" className="px-4 py-2">Contact</Link>
                    <Link to="/form" className="px-4 py-2">Get a quote</Link>

                </div>
                <div className="md:hidden flex items-center">
                    <button onClick={() => setMenuOpen(!menuOpen)} className="focus:outline-none">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className={`md:hidden ${menuOpen ? 'block' : 'hidden'}`}>
                <Link to="/" className="block px-4 py-2 hover:bg-gray-200">Home</Link>
                <Link to="/services" className="block px-4 py-2 hover:bg-gray-200">Services</Link>
                <Link to="/contact-us" className="block px-4 py-2 hover:bg-gray-200">Contact</Link>
                <Link to="/form" className="block px-4 py-2 hover:bg-gray-200">FREE quote</Link>
            </div>
        </div>
    );
}

export default Header;
