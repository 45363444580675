import React from 'react'
import TermsOfService from './components/terms'
import Header from '../homepage/components/header'
import Footer from '../homepage/components/footer'


function TermAnCo() {
  return (
    <div>
      <Header />
      <TermsOfService />
      <Footer />
    </div>
  )
}

export default TermAnCo