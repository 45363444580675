import React from 'react'
import { useNavigate } from 'react-router-dom';
function MoveInOutCleaning() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/', { state: { preselectService: 'M', fromSource: true } });
    };

    return (
        <div className="p-6">
            <div className="text-3xl font-bold mb-4 text-center font-playfair">Move In/Out</div>
            <p className="mb-4">This is the perfect package for those who are moving in or out of a house. Below you can find what is included in this service type and optional extras to add to your service to customize to your needs.</p>
            <button
                onClick={handleButtonClick}
                className="bg-gray-800 text-white py-2 px-4 rounded-lg mb-4 w-[100%] hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition ease-in-out duration-200 shadow-lg">
                Get a quote online
            </button>
            <div>
                <h2 className="text-xl font-bold mb-2">What's included</h2>
                
                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-1">Bathrooms</h3>
                    <p><i className="fas fa-check text-green-500"></i> Clean/sanitize inside and outside of toilets</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean/sanitize bottom toilet areas</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean mirrors</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean/sanitize countertops and faucets</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean windows inside</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean glass surfaces</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum rugs or carpets</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum and mop floors</p>
                    <p><i className="fas fa-check text-green-500"></i> Dry all areas</p>
                </div>
                
                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-1">Bedrooms and Living Areas</h3>
                    <p><i className="fas fa-check text-green-500"></i> Clean dust from surfaces</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum rugs or carpets</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean glass surfaces</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum and mop floors</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean windows inside</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean ceiling fans</p>
                    <p><i className="fas fa-check text-green-500"></i> Dry all areas</p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-1">Kitchen</h3>
                    <p><i className="fas fa-check text-green-500"></i> Clean/sanitize countertops and faucets</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean microwave (inside and outside)</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean stove top</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean oven</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean refrigerator exterior and interior</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean glass surfaces</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean ceiling fans</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum rugs or carpets</p>
                    <p><i className="fas fa-check text-green-500"></i> Vacuum and mop floors</p>
                    <p><i className="fas fa-check text-green-500"></i> Clean windows inside</p>
                    <p><i className="fas fa-check text-green-500"></i> Dry all areas</p>
                </div>

                <div className="mb-4">
                    <h3 className="text-lg font-bold mb-1">Not Included (Can be added)</h3>
                    <p><i className="fas fa-times-circle text-gray-500"></i> Baseboards</p>
                    <p><i className="fas fa-times-circle text-gray-500"></i> Carpet shampoo cleaning</p>
                </div>
            </div>
        </div>
    )
}

export default MoveInOutCleaning
