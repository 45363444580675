import React from 'react'
import Header from '../homepage/components/header'
import Footer from '../homepage/components/footer'
import Hero from './components/hero'
import ServiceTypes from './components/serviceTypes'


function Services() {
  return (
      <div className='font-sans'>
            <Header />
            <Hero />
            <ServiceTypes />
            <Footer />          
      </div>
  )
}

export default Services