import React, { useEffect, useRef } from 'react';
import '../../index.css';
import Hero from './components/hero';
import Header from './components/header';
import Reviews from './components/reviews';
import Awards from './components/awards';
import Footer from './components/footer';
import Form from './components/form';
import { useLocation } from 'react-router-dom';

function Homepage() {
  const formRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { fromSource, preselectService } = location.state || {};

  useEffect(() => {
    if (fromSource && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [fromSource]);

  return (
    <div className="font-sans">
      <Header />
      <Hero />
      <Reviews />
      <Awards />
      <div ref={formRef}>
        <Form preselectService={preselectService} />
      </div>
      <Footer />
    </ div>
  );
}

export default Homepage;
