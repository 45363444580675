import React from 'react'
import FAQ from './components/FAQ'
import Hero from './components/hero'
import Methods from './components/methods'
import Header from '../homepage/components/header'
import Footer from '../homepage/components/footer'

function ContactUs() {
  return (
        <div>
            <Header />
            <Hero />
            <Methods />
            <FAQ />
            <Footer />
        </div>
  )
}

export default ContactUs