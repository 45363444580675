import React from 'react';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/', { state: { fromSource: true } });
  };

  return (
    <div className="h-[60vh] bg-white flex items-center justify-center text-center px-4">
      <div className="text-black mt-20">
        <h2 className="text-6xl font-extrabold mb-4 font-playfair">Cleaning Services</h2>
        
        <ul className="list-none space-x-5 flex items-center justify-center">
          <li className="text-md"><i className="fas fa-check-circle text-green-300"></i> Standard Cleaning</li>
          <li className="text-md"><i className="fas fa-check-circle text-green-300"></i> Deep Cleaning</li>
          <li className="text-md"><i className="fas fa-check-circle text-green-300"></i> Move In/Out Cleaning</li>
        </ul>

        <button 
          onClick={handleButtonClick}
          className="bg-gray-800 w-[300px] text-white text-xl py-3 px-6 rounded-lg mt-8 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition ease-in-out duration-200 shadow-lg">
            Get a quote online
        </button>
      </div>
    </div>
  );
}

export default Hero;
