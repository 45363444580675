import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
                <div className="flex text-center flex-col md:text-left space-y-4">
                    <h2 className="text-lg font-bold">Evidence Cleaning</h2>
                    <p>1020 E Desert Inn Rd</p>
                    <p>Las Vegas, Nevada, 89109</p>
                    <p>Email: contact@worldwideevidence.com</p>
                </div>
                <div className="flex text-center md:text-left flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 mt-4 md:mt-0">
                    <Link to="/" className="hover:text-gray-400">Home</Link>
                    <Link to="/services" className="hover:text-gray-400">Services</Link>
                    <Link to="/contact-us" className="hover:text-gray-400">Contact</Link>
                    <Link to="/form" className="hover:text-gray-400">Get a quote</Link>
                </div>
                <div className="flex space-x-4 mt-4 md:mt-0">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">Facebook</a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">Twitter</a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">Instagram</a>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-8 text-center">
                <p className="text-gray-500">&copy; 2024 Evidence Cleaning. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
