import React from 'react'
import Form from '../homepage/components/form'
import Header from '../homepage/components/header'
import Footer from '../homepage/components/footer'


function FormPage() {
  return (
        <div>
            <Header />
            <div className='mt-16'>
                <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center text-black font-bold px-6 sm:px-10 md:px-20 py-4 sm:py-6 md:py-8 font-playfair">
                    Online quote form
                </h2>
            </div>
            <Form />
            <Footer />
        </div>
  )
}

export default FormPage