import React, { useState } from 'react';

const faqs = [
    { question: "What is included in my service?", answer: "We follow what is said in ther services page and extras added before the service" },
    { question: "Are there discounts?", answer: "We do offer discounts at some holiday seasons." },
    { question: "Can I change my appointment to another day", answer: "Yes, we can definitly move your appointment within one at least one day notice" },
    { question: "What if I need to cancel my appointment?", answer: "You can cancel your appointment at least 24 hours before the service" },
    { question: "What if I am not satisfied with the service?", answer: "We will come back and re-do the service for free till client satisfied" },

];

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-100 p-8 flex flex-col md:flex-row">
      <h1 className="text-5xl font-bold text-black md:w-1/4 mb-8 md:mb-0">FAQ</h1>
      <div className="md:w-3/4 space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-gray-200 p-4 rounded-lg">
            <div
              className="text-2xl font-semibold text-black cursor-pointer"
              onClick={() => handleQuestionClick(index)}
            >
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="mt-2 text-black">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
