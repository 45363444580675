import React from 'react'
import Standard from './Types/standard'
import DeepCleaning from './Types/deepCleaning'
import MoveInOutCleaning from './Types/moveInOut'

function ServiceTypes() {
  return (
        <div className="bg-gray-100 flex justify-center py-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mx-4 max-w-screen-xl">
                <div className="bg-white rounded-lg shadow-lg p-4 shadow-2xl"><Standard /></div>
                <div className="bg-white rounded-lg shadow-lg p-4 shadow-2xl"><DeepCleaning /></div>
                <div className="bg-white rounded-lg shadow-lg p-4 shadow-2xl"><MoveInOutCleaning /></div>
            </div>
        </div>
  )
}

export default ServiceTypes
