import React from 'react';
import Homepage from './pages/homepage/homepage';
import Services from './pages/services/services';
import ContactUs from './pages/contactUs/contactUs';
import TermAnCo from './pages/conditions/index';
import FormPage from './pages/form/form';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './util/layout';
import { SourceProvider, SourceWrapper } from './util/SourceContext';

// Create the router
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <SourceWrapper>
        <Layout />
      </SourceWrapper>
    ),
    children: [
      {
        path: '/',
        element: <Homepage />,
      },
      {
        path: '/services',
        element: <Services />,
      },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/terms',
        element: <TermAnCo />,
      },
      {
        path: '/form',
        element: <FormPage />,
      },
      {
        path: '*', // This will match any undefined paths
        element: <Homepage />, // Redirect to Homepage
      },
    ],
  },
]);

function App() {
  return (
    <SourceProvider>
      <RouterProvider router={router} />
    </SourceProvider>
  );
}

export default App;
